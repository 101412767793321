.menu-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #1c1c1c;

  .menu-phones {
    height: calc(100vh - 125px);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }

  img {
    width: 250px;
    margin-inline: calc((100vw - 250px) / 2);
  }

  > button {
    color: white;
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 2.25rem;
  }

  button {
    appearance: none;
    outline: none;
    border: none;
    background: transparent;
  }

  a,
  .rugaciune,
  .contact {
    color: #878787;
    text-decoration: none;
    font-size: 1.75rem;
    width: 100%;
    text-align: center;
  }

  a:active,
  .rugaciune:active,
  .contact:active {
    background: #373737;
  }
}

@media screen and (min-width: 1024px) {
  .nav-phones {
    display: none;
  }
}
