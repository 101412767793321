.dropdown {
  display: inline;
  position: relative;

  > div {
    cursor: default;
  }

  .menu {
    opacity: 0;
    position: absolute;
    top: 155%;
    left: 50%;
    transform: translateX(-50%) translateY(-10%) !important;
    width: 110%;
    padding: 0.25rem;
    background: #363636;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background: linear-gradient(
        to bottom right,
        transparent 0%,
        transparent 50%,
        #363636 50%,
        #363636 100%
      );
      bottom: calc(100% - 20px);
      left: 50%;
      transform: rotate(225deg) translateX(50%);
    }

    li {
      list-style-type: none;
      cursor: pointer;
      position: relative;

      a {
        text-decoration: none;
        text-align: center;
        color: #878787;
        pointer-events: unset;
        width: calc(100% - 10px);
        padding: 5px;
        border-radius: 10px;
        display: inline-block;
        transition: all 0.1s ease-in-out;

        &:hover {
          color: #fff;
          background: #1c1c1c;
        }
      }
    }
  }
}

.dropdown div:hover + .menu,
.menu:hover {
  opacity: 1;
  transform: unset;
  transform: translateX(-50%) !important;
  z-index: 5;
}
