.location-container {
  padding-inline: 20%;
  padding-block: 2.5%;
  background-color: #303030;
  background-image: url("../assets/images/bible.png");
  background-position: 120% 50%;
  background-size: 40%;
  background-repeat: no-repeat;
  color: white;

  h1 {
    color: white;
    font-size: 50px;
    color: #f7c678;
    margin-bottom: 2rem;
  }

  h2 {
    color: #f7c678;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-block: 1.5rem;
  }

  p {
    font-size: 20px;
  }

  img {
    width: 100%;
    border-radius: 15px;
  }
}

@media screen and (max-width: 450px) {
  .location-container {
    padding-inline: 5%;
  }

  .location-container p {
    text-align: justify;
  }
}
