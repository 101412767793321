.card-container {
  background: #303030;
  width: 100vw;
  padding-top: 120px;

  .desktop-cards {
    background: #303030;
    padding: 10vw;
    padding-top: 1vw;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    align-content: space-around;
  }

  .mobile-cards {
    padding-bottom: 40px;
  }
}

.card {
  max-width: 400px;
  width: 322px;
  height: 415px;
  margin: 2.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: sans-serif;
  position: relative;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 0.4rem 1.8rem 0 rgba(0, 0, 0, 0.7);
  cursor: pointer;

  h2 {
    text-decoration: none;
    color: white;
    font-size: 3rem;
    transform: translateY(-50px);
  }

  h1 {
    background: #3b3b3b;
    width: 100%;
    height: min-content;
    color: white;
    font-size: 1.25rem;
    line-height: 2rem;
    padding-block: 1rem;
    text-align: center;
    position: absolute;
    bottom: 5%;
  }
}

.card:hover {
  transform: scale(1.025);
}
