.video-container {
  margin-inline: 20%;
  padding-block: 1.5rem 3rem;
  position: relative;

  > span {
    position: absolute;
    color: #f7c678;
    font-size: 3rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg) translateY(60%);
    height: 11ch;
    right: 90%;
    top: 50%;
  }

  > div > iframe {
    transform: translateX(12%);
  }
}

.sermons {
  background-color: #303030;
  background-image: url("../assets/images/broken-chain.png");
  background-position: -20% 50%;
  background-size: 40%;
  background-repeat: no-repeat;
}

.selection-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding-inline: 25%;
  box-sizing: border-box;
  padding-top: 10px;

  button {
    appearance: none;
    outline: none;
    border: none;
    color: black;
    background: white;
    padding: 1rem 0.75rem;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;

    a {
      text-align: center;
      text-decoration: none;
      color: black;
      font-size: 1.15rem;
    }

    i {
      font-size: 1.45rem;
      margin-inline: 15px;
      transition: all 0.2s ease-in-out;
      margin: 0;
      margin-left: 5px;
    }
  }

  button:hover i {
    transform: scale(1.15);
    box-sizing: border-box;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem 1.5rem;
    padding-right: 2rem;
    background-color: white;
    border-radius: 10px;
    width: fit-content;

    label {
      padding-right: 0.5rem;
      border-right: 1px solid #303030;
      margin-right: 0.5rem;
      font-size: 1.15rem;
    }

    select {
      outline: none;
      border: none;
      cursor: pointer;
      appearance: none;
      margin: 0;
      position: relative;
      font-size: 1.15rem;
    }

    .select-arrow {
      width: 0.8em;
      height: 0.8em;
      background: linear-gradient(
        to bottom right,
        transparent 0%,
        transparent 50%,
        #363636 50%,
        #363636 100%
      );
      right: 5%;
      top: 50%;
      position: absolute;
      transform: translateY(-75%) rotate(45deg);
    }
  }
}

@media screen and (max-width: 450px) {
  .video-container {
    width: 90vw !important;
    margin-inline: 5vw;
    padding: 0;

    > span {
      display: none;
    }

    iframe {
      width: 90vw !important;
      height: 50vw;
      margin-inline: 5vw;
      transform: translateX(-6%) !important;
      margin-block: 30px;
      box-shadow: none;
    }
  }

  .selection-container {
    padding-inline: 2%;
    div,
    button {
      padding: 0.75rem;
    }
  }

  .sermons > p {
    padding-inline: 2% !important;
  }

  .select-arrow {
    opacity: 0;
  }
}
