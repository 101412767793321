* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
}

#app {
  overflow-x: hidden;
}

body {
  overflow: overlay;
}

button,
img,
a,
i {
  user-select: none !important;
  appearance: none;
  outline: none;
  border: none;
}

.display-none {
  display: none;
}

a:active {
  background-color: aqua;
}

::-webkit-scrollbar {
  display: none;
}

iframe,
canvas {
  border-radius: 20px;
  transition: box-shadow 0.2s ease-in-out;
}

iframe:hover {
  box-shadow: 15px 10px 15px #f7c678;
}

.program-container {
  border-radius: 10px;
  background: #303030;
  text-align: center;
  padding: 25px 50px;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  width: 50%;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(50%);
  transition: opacity .3s ease-in-out;
  z-index: 10;
}

.program-container.not-display {
  opacity: 0;
}

.program-container h2 {
  margin-bottom: 20px;
  font-size: 1.75rem;
}

.announcements {
  border-radius: 10px;
  background-color: #303030;
  text-align: left;
  padding: 25px 50px;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.7);
  background-image: url(./assets/images/announcement-img/dependente-care-duc-viata-in-ruina.jpg);
  background-size: 80%;
  background-position: right -100px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  width: 50%;
  height: 200px;
  z-index: 5;
}

.announcements h1 {
  text-align: left;
  font-size: 30px;
  color: #f7c678;
}

.announcements p {
  font-size: 15px;
  max-width: 75%;
  margin-bottom: 15px;
}

.announcements span {
  color: #f7c678;
}

.announcements a {
  color: #f7c678;
  text-decoration: none;
}

.announcements a:hover {
  text-decoration: underline;
}

.announcements i {
  width: 2ch;
  text-align: center;
  margin-right: 5px;
}

.txt-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(244, 244, 244, 0) 0%, rgb(48, 48, 48) 38%);
  position: absolute;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  padding: 25px 50px;
}

.buttons-container {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  padding: 10px;
  background-color: #303030;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.buttons-container::before {
  content: "";
  position: absolute;
  width: 31%;
  height: 3px;
  background-color: #f7c678;
  bottom: 10px;
  left: 27px;
  transition: all .3s ease-in-out;
}

.buttons-container.announcements-tab::before {
  transform: translateX(calc(100% + 32px));
}

.buttons-container button {
  border-radius: 5px;
  margin-inline: 5px;
  font-size: 20px;
  color: white;
  background-color: transparent;
  text-align: center;
  padding: 6px;
  cursor: pointer;
}

@media screen and (max-width: 1171px) {
  .desktop-navbar {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  iframe {
    transform: unset;
    box-shadow: unset !important;
  }

  .program-container {
    align-items: center;
    width: 85vw;
    padding: 5px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(45%);
  }
  
  .announcements {
    width: 85vw;
    padding: 5px;
    transform: translateY(45%);
    padding: 5px;
    background-position: 200px;
    background-position: right center !important;
  }
  
  .announcements h1 {
    font-size: 20px;
  }

  .announcements p {
    font-size: 10px;
    margin-bottom: 8px;
  }

  .announcements span {
    font-size: 12px;
  }

  .txt-container {
    padding: 10px 20px;
    /* background: linear-gradient(270deg, rgba(244,244,244,0) 0%, rgba(48,48,48,1) 70%); */
  }

  .buttons-container {
    transform: scale(.85) translateX(-50%);
    bottom: 96%;
    left: 45%;
  }

  .hero::before {
    display: none;
  }

  .program-container {
    padding: 15px;
  }

  .program-container h2 {
    font-size: 1rem;
  }

  tr {
    display: flex;
    flex-wrap: nowrap;
  }

  footer {
    flex-direction: column;
    align-items: center;
  }

  footer img {
    width: 50vw !important;
  }

  footer .logo-motto {
    margin-bottom: 30px;
  }

  footer .contact a {
    font-size: 1.25rem;
  }

  footer .contact-social {
    margin-bottom: 30px;
  }

  footer .social {
    margin-top: 20px;
  }

  footer p {
    width: 90vw;
    text-align: center;
  }

  footer .logo-motto h2 {
    font-size: 1rem;
  }
}
