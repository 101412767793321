.form-container {
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-direction: column;
  background: #f1f1f1;
  position: fixed;
  z-index: 100;
  width: 300px;
  font-family: sans-serif;
  overflow: hidden;
  border-radius: 10px;
  padding: 40px;
  bottom: 2.5vh;
  right: 2.5vh;

  .form-img {
    width: 100%;
    margin-inline: 0;
  }

  hr {
    height: 15px;
    position: absolute;
    bottom: 1.5%;
    width: 70%;
    left: 15%;
    border-radius: 10px;
    background-color: #b0b0b0;
    border: none;
  }

  .progress-value {
    background-color: #1c1c1c;
    width: 0;
    max-width: 75%;
    transition: width 0.25s ease-in-out;
  }

  .progress::before {
    content: "";
    position: absolute;
    height: 100%;
    width: calc(100% / attr(data-progress));
    transition: all 0.15s ease-in-out;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 110%;
    align-self: center;
    transform: translateY(-100%);

    h1,
    button {
      font-size: 1.25rem;
      appearance: none;
      outline: none;
      border: none;
      background: none;
    }
  }

  .go-back {
    background: white;
    appearance: none;
    outline: none;
    border: none;
    text-align: center;
    box-sizing: border-box;
    padding: 0.5rem;
    position: absolute;
    top: 32%;
    left: 5%;
    transform: translateY(-50%);

    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    color: #7b7b7b;
    font-size: 1.25rem;
    box-shadow: 3px 1px 5px #7b7b7b;
  }

  .input-container {
    width: 100%;
    min-height: 300px;
    height: 300px;
    margin-inline: calc(-5%);
    padding: 10% 5%;
    border-radius: 20px;
    background: white;
    border: none;
    outline: none;
    -moz-user-focus: none;
    display: flex;
    align-items: space-around;
    justify-content: space-around;
    flex-direction: column;
    position: relative;

    textarea {
      height: 80%;
      resize: none;
      outline: none;
      border: none;
      padding: 1rem 0.5rem;
      background: white;
      border-radius: 10px;
      box-sizing: border-box;
    }

    button {
      border: none;
      background: #b0b0b0;
      border-radius: 10px;
      padding: 5px 2px;
      position: absolute;
      width: calc(90% - 5px);
      left: 50%;
      transform: translateX(-50%);
      bottom: 1%;
      font-size: 1rem;
      cursor: pointer;
    }

    input {
      border: none;
      font-size: 2rem;
      inline-size: 90%;
      display: block;
      outline: none;
      margin-top: 2rem;
      transform: translateY(-100%);
    }
  }

  .email {
    box-sizing: border-box;
    padding: 3rem;
    font-size: 1.8rem;
    word-wrap: normal;
  }

  .checkmark {
    min-height: 150px;
    height: 150px;
    width: 150px;
    margin-inline: auto;
    margin-block: 100px;
    border-radius: 50%;
    position: relative;
    border: 10px solid transparent;
    transition: border-color 0.3s ease-in-out;
    animation: ease-in-out 1 0.6s both border;
  }

  .checkmark::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 7px;
    transform-origin: top center;
    transition: all 0.3s ease-in-out;
    animation: ease-in-out 1 0.8s both checkmark;
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translateX(-20%) translateY(-130%);
  }
}

.input-container.animate__animated.animate__slideInRight {
  --animate-duration: 0.25s;
}

@keyframes border {
  from {
    border-color: transparent;
  }
  to {
    border-color: #7b7b7b;
  }
}

@keyframes checkmark {
  0% {
    width: 0;
    height: 0;
  }
  40% {
    height: 30%;
    width: 0;
    border-left: 7px solid #7b7b7b;
  }
  100% {
    width: 60%;
    height: 30%;
    border-left: 7px solid #7b7b7b;
    border-bottom: 7px solid #7b7b7b;
  }
}

@media screen and (max-width: 450px) {
  .form-container {
    bottom: 0;
    right: 0;
    width: calc(100vw - 80px);
    height: calc(100vh - 80px);
    border-radius: 0;
  }

  .controls {
    transform: translateY(-275%) !important;
  }
}
