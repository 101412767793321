@font-face {
  font-family: "Open Regular";
  src: url("../assets/fonts/OpenSans-Regular.ttf");
}

footer {
  background: #1c1c1c;
  color: #fff;
  display: flex;
  padding-block-start: 1.2rem;
  padding-block-end: 4rem;
  position: relative;

  * {
    font-family: "Open Regular";
  }

  .logo-motto {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 30vw;
    }

    h2 {
      text-align: center;
    }
  }

  p {
    color: #828282;
    font-size: 1rem;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .contact-social {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .contact a {
    font-size: 1.5rem;
    color: #b0b0a6;
    transition: color 0.1s linear;
    text-decoration: none;
  }

  .contact a:hover {
    color: rgba(255, 255, 255, 0.85);
  }

  .social {
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    li {
      background-color: #fff;
      border-radius: 50%;
      list-style-type: none;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      padding: 6px;
      position: relative;
      margin-inline: 20px;
      cursor: pointer;
    }

    a {
      color: #1c1c1c;
      font-size: 1.4rem;
      width: 100%;
      height: 100%;
      display: inline;
    }

    i {
      transition: transform 0.1s ease-in-out;
    }
  }

  .social li:hover i {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 450px) {
  .logo-motto {
    img {
      width: 150% !important;
    }

    h2 {
      font-size: larger !important;
      white-space: nowrap;
    }
  }
}
