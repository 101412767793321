.kids {
  .kids-container {
    margin: 0;
    padding-inline: 20%;
    background: #303030;
    position: relative;
    color: #f7c678;
    text-align: justify;

    h1 {
      color: #f7c678;
      font-size: 3rem;
    }

    p {
      font-size: large;

      &::first-letter {
        margin-left: 4ch;
      }
    }

    > img {
      width: 96%;
      margin-inline: 2%;
      margin-block: 30px;
      cursor: pointer;
      border-radius: 20px;
      border: 1px solid white;
    }
  }
}

.verses-images {
  background-color: #303030;
  padding-inline: 10%;

  img {
    width: 40%;
    margin-inline: 5%;
    margin-top: 15vh;
    margin-bottom: 10vh;
    border-radius: 30px !important;
    transition: transform 0.25s ease-in-out;

    &:first-of-type {
      transform: rotate(-20deg) translateX(18%);
      z-index: 1;
    }

    &:first-of-type:hover {
      transform: rotate(-20deg) translateX(18%) scale(1.05);
      cursor: pointer;
      z-index: 3;
    }

    &:last-of-type {
      transform: rotate(20deg) translateX(-15%);
      z-index: 2;
    }

    &:last-of-type:hover {
      transform: rotate(20deg) translateX(-15%) scale(1.05);
      cursor: pointer;
      z-index: 3;
    }
  }
}

@media all and (max-width: 600px) {
  .kids-container {
    padding-inline: 5% !important;
    font-size: 0.75rem !important;

    > img {
      width: 95% !important;
      margin-inline: 2.5% !important;
    }
  }

  .verses-images {
    padding: 5%;

    img {
      margin-inline: none;
      transform: translateX(-5%) !important;
    }
  }
}
