.hero {
  height: 100vh;
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  h1 {
    font-size: 60px;
    text-align: center;
  }

  iframe {
    z-index: -10;
    border-radius: unset;
    border: 0;
    position: absolute;
    width: 100vw;
    aspect-ratio: 16 / 9;
    margin: 0;

    &:hover {
      box-shadow: unset;
    }
  }

  table {
    font-weight: bold;
    font-size: 1.5rem;

    td {
      text-align: center;
      width: 18ch;
      display: inline-block;
      padding: 0.5rem;
    }

    td {
      padding: 1rem;
      border: 1px solid white;
    }

    tr:first-child td {
      border-top: 0;
    }

    tr td:first-child {
      border-left: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }

    tr td:last-child {
      border-right: 0;
    }
  }
}

.hero::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  background: linear-gradient(
    to bottom right,
    transparent 0%,
    transparent 50%,
    #303030 50%,
    #303030 100%
  );
  transform: rotateY(180deg);
}

@media screen and (max-width: 1024px) {
  .hero {
    height: 60vh;
    background-size: 100%
  }

  .hero:has(h1[class="home-hero-text"]) {
    background-position: 68% center;
  }

  .home-hero-text {
    font-size: 30px !important;
  }

  table {
    font-size: 0.75rem !important;
  }

  .hero::before {
    bottom: -0.5%;
  }
}
