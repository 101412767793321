header {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 10px;
  position: fixed;
  width: 100vw;
  z-index: 10;

  img {
    width: 150px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    cursor: pointer;
  }

  nav {
    font-size: 1.25rem;
    padding: 0.25rem 0.25rem;
    background: #1c1c1c;
  }

  nav:first-of-type {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  nav:last-of-type {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .nav-link {
    padding: 0.5rem 1rem !important;
    color: #878787;
    text-decoration: none;
    background: #1c1c1c;
    width: 12ch !important;
    display: inline-block;
    text-align: center;
    border-radius: 10px;
    border: 0;
    transition: all 0.1s ease-in-out;
  }

  .nav-link:hover {
    background: #373737;
    color: white;
  }

  button {
    appearance: none;
    outline: none;
    border: none;
  }
}
