.nav-phones {
  width: 100vw;
  box-sizing: border-box !important;
  overflow: hidden;
  height: 120px;
  padding: 2rem 1rem;
  position: relative;
  color: white;
  background: transparent;
  position: absolute;

  > img {
    width: 275px;
    margin-inline: auto;
    margin-block: 1rem;
  }

  button {
    position: absolute;
    color: white;
    background: transparent;
    appearance: none;
    outline: none;
    border: none;
    right: 1rem;
    top: 1rem;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1174px) {
  .nav-phones {
    display: flex;
  }
}
